@import url("https://fonts.googleapis.com/css?family=Montserrat:500,700");
@import url("https://fonts.googleapis.com/css?family=Rubik:500,700");
@import "~ng-pick-datetime/assets/style/picker.min.css";
@import "~ag-grid-community/dist/styles/ag-grid.css";
@import "~ag-grid-community/dist/styles/ag-theme-balham.css";

.montserrat {
  font-family: "Montserrat";
}
.rubik {
  font-family: "Rubik !important";
}

html,
body {
  height: 100%;
  font-family: "Montserrat" !important;
  margin: 0;
  overflow: hidden;
  color: #5c5c5c;
}

.close-btn {
  float: right;
  color: black;
  position: relative;
  cursor: pointer;
}

.loader {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  height: 9rem;
}

.ck-editor__editable {
  height: 200px;
}

/* Main Container CSS for No Repeat Background Image  */
.main-container {
  height: 100%;
  background-image: url(./assets/images/BG.png);
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
  position: absolute !important;
  min-width: 100%;
  width: 100%;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
}

/* Mat Drawer Side and Drawer - Drawer Content CSS  */

.mat-drawer-side {
  border-right: 1px solid #ffffff00 !important;
}

.mat-drawer {
  background-color: rgba(255, 255, 255, 0) !important;
  color: rgba(0, 0, 0, 0.87);
}

.mat-drawer-content {
  position: relative;
  z-index: 1;
  display: block;
  height: 100%;
  overflow: auto;
  background-color: rgba(255, 255, 255, 0) !important;
}

/* Mat List Base CSS  */
.mat-list-base {
  padding-top: 8px;
  display: block;
  -webkit-tap-highlight-color: transparent;
}

.mat-list-base .mat-list-item.mat-list-item-with-avatar,
.mat-list-base .mat-list-option.mat-list-item-with-avatar {
  height: 128px !important;
}

/* Main Sidenav Content and Sidenav CSS */
.main-sidenav-content {
  /* padding-left: 45px; */
  padding-left: 10px;
}

.main-sidenav-content h1 {
  padding-top: 40px;
  font-size: 30px;
  margin: unset;
  color: #c1011a;
  font-family: "Montserrat", sans-serif;
}

.main-sidenav {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 275px;
}

/* Custom CSS for Placeholder Text */
input::-webkit-input-placeholder {
  color: #5c5c5c !important;
  font-weight: 200;
  font-size: 19px;
  font-family: "Rubik";
}

input:-moz-placeholder {
  /* Firefox 18- */
  color: #5c5c5c !important;
  font-weight: bold;
  font-size: 19px;
  font-family: "Montserrat", sans-serif;
}

input::-moz-placeholder {
  /* Firefox 19+ */
  color: #5c5c5c !important;
  font-weight: bold;
  font-size: 19px;
  font-family: "Montserrat", sans-serif;
}

input:-ms-input-placeholder {
  color: #5c5c5c !important;
  font-weight: bold;
  font-size: 19px;
  font-family: "Montserrat", sans-serif;
}

.profile-container {
  margin-top: 18px;
  width: 90%;
  border: 1px solid #0606060d;
  /* height: 420px; */
  height: 370px;
  min-height: 370px;
  max-height: 370px;
  padding-left: 72px;
  padding-top: 65px;
  overflow-y: scroll;
}

/* Button Container CSS for Top Buttons */
.search-form-field {
  width: 30%;
  max-height: 48px !important;
  height: 48px !important;
  font-weight: bold;
  font-size: 16px;
  -webkit-text-fill-color: #5c5c5c !important;
}

.button-container {
  display: flex;
  height: 48px;
  width: 95%;
  margin-top: 18px;
}

.dropdown-button-holder {
  display: flex;
  justify-content: space-around;
}

.button-seperator {
  padding-left: 18px;
}

.disabled-div {
  pointer-events: none;
}
.disabled-div .split-button {
  background-color: #e2e2e259;
  color: #a3a3a391;
}

.warning-button {
  color: #fff;
  background-color: #f0ad4e !important;
  border-color: #eea236 !important;
}

.primary-button {
  background-color: #4b88a2;
  color: white;
  font-weight: 600;
  font-size: 20px;
  height: 48px;
  font-family: "Rubik", sans-serif;
}

.action-button {
  background-color: #ffffff;
  color: #494949;
  font-weight: 600;
  font-size: 20px;
  height: 48px;
  width: 190px;
  font-family: "Rubik", sans-serif;
  border: 1px solid #cbcbcb !important;
  
}

.mat-focus-indicator.action-button.header-height.mat-raised-button.mat-button-base { padding:0 12px!important; width: auto;}

.action-button img {
  width: 30px;
  padding-left: 12px;
  padding-bottom: 5px;
}

.edit-button {
  background-color: #01c1a8;
  color: white;
  font-weight: 600;
  font-size: 20px;
  height: 48px;
  width: 60px !important;
  font-family: "Rubik", sans-serif;
}

.left {
  float: left !important;
}

.right {
  float: right !important;
}

.full-width {
  width: 100%;
}

.paginator-button-container {
  display: flex;
  /* width: 95%; */
  width: 75%;
  position: fixed;
  bottom: 10px;
  justify-content: flex-end;
}

.paginator-button-container-dialog {
  display: flex;
  width: 95%;
  justify-content: flex-end;
}

.mat-drop-down-icon {
  width: 25px;
}

.fixed-container-button {
  width: 100%;
  height: 50px;
  padding: 20px;
  background-color: #e2e2e2;
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  z-index: 1;
}

.download-pdf-button {
  background-color: #4b88a2;
  color: white;
  font-weight: 600;
  font-size: 20px;
  height: 48px;
  font-family: "Rubik", sans-serif;
  float: right;
  margin-right: 4% !important;
}

/* Mat Menu toggle button and for Split Button Global CSS */
mat-button-toggle {
  color: #5c5c5c;
  font-weight: 600;
  font-size: 20px;
}

.split-button {
  background-color: #e2e2e2;
  color: #5c5c5c;
}

.search-icon {
  width: 26px;
  vertical-align: sub;
}

.pointer {
  cursor: pointer;
}

::ng-deep mat-button-toggle .drop-down-icon {
  border-left: 1px solid black;
}
.cdk-overlay-connected-position-bounding-box {
  position: absolute !important;
  z-index: 1000 !important;
  /* top: 202px !important; */
  /* left: 1060px !important; */
  /* width: 130px; */
}

::ng-deep .cdk-overlay-connected-position-bounding-box {
  position: absolute !important;
  /* z-index: 1000; */
  /* display: flex; */
  /* flex-direction: column; */
  /* min-width: 1px; */
  /* min-height: 1px; */
}

::ng-deep .mat-menu-panel {
  width: 100% !important;
  /* min-width: 130px !important;
    max-width: none !important; */
  overflow: auto;
  max-height: calc(100vh - 48px);
  border-radius: 4px;
  outline: 0;
  min-height: 64px;
}

/* Custom Scroll CSS Start*/
::-webkit-scrollbar {
  width: 10px;
}

::-webkit-scrollbar-track {
  background: #f1f1f1;
}

::-webkit-scrollbar-thumb {
  background: #cbcbcb;
}

::-webkit-scrollbar-thumb:hover {
  background: #cbcbcb;
}

/* Custom Dialog Container CSS*/
.mat-dialog-container {
  box-shadow: 0 11px 15px -7px rgba(0, 0, 0, 0.2),
    0 24px 38px 3px rgba(0, 0, 0, 0.14), 0 9px 46px 8px rgba(0, 0, 0, 0.12);
  background: #1b2143f6 !important;
  padding: unset !important;
  border-radius: 8px !important;
}

/* Custom Material Tab CSS Start*/

.mat-tab-container {
  margin-top: 27px;
  height: 48px;
}

.mat-tab-header {
  border-bottom: 1px solid rgba(0, 0, 0, 0.6);
}

.mat-tab-body-wrapper {
  position: relative;
  overflow: hidden;
  display: flex;
  transition: height 0.5s cubic-bezier(0.35, 0, 0.25, 1);
}

.mat-tab-content-details {
  width: 100%;
  border: 1px solid #0606060d;
  height: 70vh;
  min-height: 350px;
  max-height: 75vh;
  padding-left: 72px;
  padding: 3%;
  overflow-y: scroll;
  box-sizing: border-box;
}

.mat-tab-group.mat-primary .mat-ink-bar,
.mat-tab-nav-bar.mat-primary .mat-ink-bar {
  background-color: transparent;
}

.mat-tab-label {
  height: 48px !important;
  background-color: transparent;
  font-size: 20px;
  opacity: 1.6 !important;
  background-color: #e2e2e2;
  border-left: 1px solid #bbb9b9a2;
  border-right: 1px solid #bbb9b9a2;
  color: #5c5c5c;
}

.mat-tab-label.mat-tab-label-active {
  background-color: transparent;
  font-size: 20px;
  color: #c1011a;
  height: 48px !important;
  border: 0px solid#5c5c5c;
}

.mat-tab-header {
  border-bottom: 0.1px solid rgba(0, 0, 0, 0.001);
}

.mat-tab-label .mat-tab-label-content {
  display: inline-flex;
  justify-content: center;
  align-items: center;
  white-space: nowrap;
  font-size: 25px;
  font-family: "Rubik", sans-serif;
  font-weight: 600;
}

.mat-slide-toggle-thumb {
  box-shadow: 0 2px 1px -1px rgba(0, 0, 0, 0.2), 0 1px 1px 0 rgba(0, 0, 0, 0.14),
    0 1px 3px 0 rgba(0, 0, 0, 0.12);
  background-color: #c1011a;
}

.mat-slide-toggle-bar {
  background-color: rgb(255, 255, 255);
  border: 0.5px solid #c1011a;
}

.mat-slide-toggle.mat-checked .mat-slide-toggle-thumb {
  background-color: #c1011a;
}

.mat-slide-toggle.mat-checked .mat-slide-toggle-bar {
  background-color: #c1011a;
  border: 0.5px solid #c1011a;
}

/* Booklet-Profile-Div Designs for all pages */

.booklet-flex-container {
  display: flex;
  /* justify-content: center; */
  padding-left: 5%;
}

.booklets {
  border: 2px solid #4b88a2;
  border-left: 1px solid white;
  height: 129px;
  width: 100%;
  padding: 10px;
  padding-bottom: 0px;
  margin-bottom: 40px;
}

.booklets:focus {
  outline: 0;
}
 

.booklet-image {
  float: left;
  margin-left: -102px;
  width: 150px;
  height: 153px;
  margin-top: -22px;
}

.client-logo-inside-booklet {
  margin-top: 42px;
  max-width: 124px;
  float: left;
  margin-left: -125px;
  max-height: 52px;
}

.profile-image {
  float: left;
  margin-left: -93px;
  width: 160px;
  height: 160px;
  margin-top: -30px;
}

/* .user-profile-image {
    position: relative;
    z-index: 1;
    margin-left: -158px;
    width: 150px;
    height: 150px;
    margin-top: -27px;
    border-radius: 5px;
    float: left;
} */

.user-profile-image {
  position: relative;
  z-index: 1;
  margin-left: -155px;
  width: 150px;
  height: 150px;
  margin-top: -24px;
  border-radius: 5px;
  float: left;
}

.profile-name {
  font-family: "Montserrat", sans-serif;
  /* font-size: 34px; */
  font-size: 2vw;
  font-weight: bold;
  margin: unset !important;
  padding-left: 90px;
  color: #4c4c4c;
}

.profile-details {
  font-family: "Rubik", sans-serif;
  /* font-size: 21px; */
  font-size: 1.48vw;
  color: #5c5c5c;
  margin: unset !important;
  padding-left: 90px;
}

/* Condition styles for Booklet and Frozen-items component */

.booklet-name {
  padding-top: 15px;
  font-family: "Montserrat", sans-serif;
  font-size: 1.8vw;
  font-weight: bold;
  margin: unset !important;
  padding-left: 15px;
  color: #4c4c4c;
}

.booklet-name-condition {
  font-family: "Montserrat", sans-serif;
  font-size: 2.1vw;
  font-weight: bold;
  margin: unset;
  padding-left: 70px;
}

.booklet-created-by {
  font-family: "Rubik", sans-serif;
  font-size: 1.7vw;
  color: #4b88a2;
  margin: unset;
  padding: 12px 70px;
}

.booklet-created-by-condition {
  font-family: "Rubik", sans-serif;
  font-size: 1.9vw;
  color: #4b88a2;
  margin: unset;
  padding-left: 70px;
  padding-top: 30px;
  /* padding-top: 42px; */
}

.booklet-created-by-condition-second {
  font-family: "Rubik", sans-serif;
  font-size: 24px;
  color: #4b88a2;
  margin: unset;
  padding-left: 70px;
  padding-top: 5px;
}

.booklet-created-by-condition-third {
  font-family: "Rubik", sans-serif;
  font-size: 24px;
  color: #4b88a2;
  margin: unset;
  padding-left: 70px;
  padding-top: 28px;
}

/* Custom CSS for Action buttons */
.preview-action-button-container {
  height: 75px;
  margin: 5px;
  text-align: center;
  position: absolute;
  z-index: 1000;
}

.preview-action-button-container a {
  margin: 0 10px;
}
.action-button-container {
  width: 240px;
  height: 141px;
  display: flex;
  flex-wrap: wrap;
}
.preview-icons {
  background-color: unset;
  font-size: 22.5px;
  width: 181px;
  height: 76px;
  font-family: "Rubik", sans-serif !important;
}

.preview-icons img {
  padding-left: 15px;
  width: 35px;
}

.export-button {
  background-color: #01c1a8;
  color: white;
  font-size: 22.5px;
  width: 192px;
  height: 76px;
  border-radius: unset !important;
  margin-left: -1px;
  font-family: "Rubik", sans-serif !important;
}

.export-button img {
  padding-left: 15px;
  width: 35px;
}

.edit-button {
  background-color: #357266;
  color: white;
  font-size: 22.5px;
  width: 116px;
  height: 67px;
  border-radius: unset !important;
  margin-left: -1px;
  font-family: "Rubik", sans-serif !important;
}

.edit-button img {
  padding-left: 15px;
  width: 35px;
}

.refresh-button {
  background-color: #4b88a2;
  color: white;
  font-size: 22.5px;
  width: 181px;
  height: 76px;
  border-radius: unset !important;
  margin-left: -1px;
  font-family: "Rubik", sans-serif !important;
}

.refresh-button img {
  padding-left: 15px;
  width: 35px;
}

.freeze-button {
  background-color: #4b88a2;
  color: white;
  width: 61px;
  height: 67px;
  border-radius: unset !important;
  margin-left: -1px;
  font-family: "Rubik", sans-serif !important;
}

.freeze-button img {
  width: 33px;
}

.copy-button {
  background-color: #357266;
  color: white;
  font-size: 21px;
  height: 67px;
  width: 116px;
  margin-left: -1px;
  border-radius: unset !important;
  font-family: "Rubik", sans-serif !important;
}

.copy-button img {
  width: 30px;
}

.restore-button {
  background-color: #01c1a8;
  color: white;
  font-size: 22px;
  height: 142px;
  width: 164px;
  border-radius: unset !important;
  font-family: "Rubik", sans-serif !important;
}
.hide-page {
  z-index: 90;
  /* background-color: grey; */
  opacity: 0.7;
  height: 100%;
  width: 100%;
  top: 0;
  position: fixed;
  left: 0;
  overflow: hidden;
}
.restore-button img {
  width: 35px;
}

button.mat-menu-item {
  width: 110px !important;
  font-size: 18px !important;
  font-family: "Rubik" !important;
  color: #5c5c5c !important;
}

.mat-button-toggle-button {
  border: 0;
  background: 0 0;
  color: inherit;
  padding: 0;
  margin: 0;
  font: inherit;
  outline: 0;
  width: 100%;
  cursor: pointer;
  font-family: "Rubik" !important;
}

/*  Booklet Dialog  Warning for All Booklet,Freeze,Restore Dialog */
.messageBody-centered-add-session-note {
  position: fixed;
  z-index: 100;
  top: 50%;
  left: 50%;
  width: 1300px;
  height: 750px;
  transform: translate(-50%, -50%);
  background-color: #4b88a2 !important;
  border-radius: 0.8rem;
}

.messageBody-centered-add-session-note-content {
  background-color: #1b2143 !important;
  margin: 8% 1% 1% 1%;
  padding: 3%;
  position: absolute;
  border-radius: 0.8rem;
  height: 74%;
  width: 92%;
  overflow: scroll;
  overflow-x: hidden;
}

.messageBody-centered {
  position: fixed;
  z-index: 100;
  top: 45%;
  left: 50%;
  transform: translate(-50%, -50%);
  padding: 90px 120px;
}

.static-warning-text {
  color: white;
  font-family: "Rubik", sans-serif;
  font-size: 23px;
  text-align: center;
  margin: 30px;
  margin-top: 15px;
}

.selected-booklet-name {
  color: #f2d398;
  font-family: "Rubik", sans-serif;
  font-size: 23px;
  text-align: center;
  margin: 30px;
}

.messageBody-button-container {
  display: flex;
  justify-content: space-around;
  padding-left: 50px;
  padding-right: 50px;
}

.warning-button-dialog {
  background-color: #c1011a;
  color: white;
  font-weight: 600;
  font-size: 20px;
  height: 40px;
  width: 100px;
  font-family: "Rubik", sans-serif;
}

.success-button-dialog {
  background-color: #01c1a8;
  color: white;
  font-weight: 600;
  font-size: 20px;
  height: 40px;
  width: 100px;
  font-family: "Rubik", sans-serif;
}

/* Add Profile Style Sheets */

.add-profile-inner-header {
  color: #c1011a;
  font-size: 30px;
  font-family: "Rubik", sans-serif;
  font-weight: bold;
}

.double-card-division-flex {
  width: 100%;
  display: flex;
}

/* Button for submit and reset */

.reset-button {
  background-color: #c1011a;
  color: white;
  font-weight: 600;
  font-size: 20px;
  height: 40px;
  width: 100px;
  float: right;
  font-family: "Rubik", sans-serif;
  margin-left: 1% !important;
  margin-right: 1% !important;
}

.submit-button {
  background-color: #01c1a8;
  color: white;
  font-weight: 600;
  font-size: 20px;
  height: 40px;
  width: 100px;
  float: right;
  font-family: "Rubik", sans-serif;
  margin-left: 1% !important;
  margin-right: 1% !important;
}

/* Copy Restore Edit dialog css */
.search-form-field-white {
  width: 30%;
  max-height: 48px !important;
  height: 48px !important;
  font-weight: bold;
  font-size: 16px;
  -webkit-text-fill-color: white;
}

.dialog-button-container {
  display: flex;
  height: 48px;
  padding-left: 25px;
  padding-top: 3%;
}

.dialog-filter-button {
  background-color: #01c1a8;
  color: white;
  font-weight: 600;
  font-size: 20px;
  height: 48px;
}

.dialog-text-header {
  color: white;
  font-weight: 500;
  font-size: 25px;
  margin: unset;
  padding-top: 10px;
  padding-right: 20px;
}

.dialog-booklets {
  border: 2px solid #f7f7f7;
  border-left: 1px solid white;
  height: 98px;
  width: 80%;
  padding: 17px;
  padding-bottom: 0px;
  margin-bottom: 40px;
  background-color: white;
  cursor: pointer;
}

.dialog-booklet-image {
  float: left;
  margin-left: -93px;
  width: 130px;
  height: 130px;
  margin-top: -26px;

  /* float: left;
    margin-left: -93px;
    width: 140px;
    height: 140px;
    margin-top: -32px; */
}

.dialog-booklet-name {
  font-family: "Montserrat", sans-serif;
  font-size: 28px;
  font-weight: bold;
  margin: unset;
  padding-left: 70px;
  color: #4c4c4c;
}

.dialog-booklet-name-condition {
  font-family: "Montserrat", sans-serif;
  font-size: 20.5px;
  font-weight: bold;
  margin: unset;
  padding-left: 70px;
  color: #4c4c4c;
}

.dialog-booklet-created-by {
  font-family: "Rubik", sans-serif;
  font-size: 22px;
  color: #4b88a2;
  margin: unset;
  padding-left: 70px;
}

/* .dialog-booklet-created-by-condition {
    font-family: 'Rubik', sans-serif;
    font-size: 22px;
    color: #4b88a2;
    margin: unset;
    padding-left: 70px;
    padding-top: 42px;
} */

.dialog-booklet-created-by-condition {
  font-family: "Rubik", sans-serif;
  font-size: 25px;
  color: #4b88a2;
  margin: unset;
  padding-left: 70px;
  padding-top: 25px;
}

.dialog-booklet-created-by-condition-second {
  font-family: "Rubik", sans-serif;
  font-size: 20px;
  color: #4b88a2;
  margin: unset;
  padding-left: 70px;
  padding-top: 5px;
}

.dialog-booklet-created-by-condition-third {
  font-family: "Rubik", sans-serif;
  font-size: 20px;
  color: #4b88a2;
  margin: unset;
  padding-left: 70px;
  padding-top: 28px;
}

/* Edit Profile add Profile CSS */

.form-field-heading {
  font-size: 20px;
  font-weight: bold !important;
  font-family: "Rubik", sans-serif;
  color: #797676 !important;
}

.user-card {
  padding: 15px;
  margin-top: 1%;
  margin-bottom: 1%;
  margin-right: 1%;
  border: 1px solid #0606060d;
}

.user-half-card {
  padding: 15px;
  margin-top: 1%;
  margin-bottom: 1%;
  margin-right: 1%;
  width: 40%;
  flex: 33.33%;
}

.add-button {
  width: 30px;
  padding-left: 30px;
  vertical-align: sub;
  cursor: pointer;
}

.remove-button {
  width: 30px;
  vertical-align: sub;
  cursor: pointer;
}

/* Add Booklet and Edit Booklet Styles */

.file-picker-text {
  text-align: center !important;
  font-family: "Rubik", sans-serif;
  color: #5c5c5c;
  font-weight: 600;
  font-size: 18px;
}

.picture-container-outer-div {
  padding: 15px;
  margin-top: 1%;
  margin-bottom: 1%;
  margin-right: 1%;
  border: 1px solid #0606060d;
}

.picture-container-inner-div {
  height: 380px;
  position: relative;
  border: 2px solid #c1011a;
  border-radius: 15px;
  cursor: pointer;
  justify-content: center;
  display: flex;
  position: relative;
}
.centered-image-upload-text {
  margin: 0;
  position: absolute;
  top: 5%;
  left: 50%;
  -ms-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
  font-family: "Rubik";
  font-size: 30px;
  color: #5c5c5c;
}

.uploader-image {
  width: 100%;
  border-radius: 10px;
  height: 380;
}
/* snack bar color classes */
.alert-success {
  color: #3c763d;
  background-color: #dff0d8 !important;
  border-color: #d6e9c6;
  z-index: 1000;
  position: fixed;
}
.alert-info {
  color: #31708f;
  background-color: #d9edf7;
  border-color: #bce8f1;
  z-index: 1000;
  position: fixed;
}
.alert-warning {
  color: #8a6d3b;
  background-color: #fcf8e3;
  border-color: #faebcc;
  z-index: 1000;
  position: fixed;
}
.alert-danger {
  color: #a94442;
  background-color: #f2dede;
  border-color: #ebccd1;
  z-index: 1000;
  position: fixed;
  display: block;
}

.danger-snackbar {
  color: #c1011a;
  max-width: 70vw !important;
  min-width: 344px;
  text-align: center;
  /* background: #1b2143f6; */
  background: #e2e2e2;
  font-weight: bold !important;
  box-shadow: 0 3px 5px -1px rgba(0, 0, 0, 0.2),
    0 6px 10px 0 rgba(0, 0, 0, 0.14), 0 1px 18px 0 rgba(0, 0, 0, 0.12);
}

/* .warning-snackbar{
    color: #fcfcfc;
    max-width: 70vw !important;
    min-width: 344px;
    text-align: center;
    background: #e1db3b70;
    box-shadow: 0 3px 5px -1px rgba(0,0,0,.2), 0 6px 10px 0 rgba(0,0,0,.14), 0 1px 18px 0 rgba(0,0,0,.12);
  } */

.success-snackbar {
  color: #01c1a8;
  max-width: 70vw !important;
  min-width: 344px;
  text-align: center;
  /* background: #1b2143f6; */
  background: #e2e2e2;
  font-weight: bold !important;
  box-shadow: 0 3px 5px -1px rgba(0, 0, 0, 0.2),
    0 6px 10px 0 rgba(0, 0, 0, 0.14), 0 1px 18px 0 rgba(0, 0, 0, 0.12);
}

.mat-simple-snackbar {
  font-size: 24px;
  display: inline !important;
}

.mat-form-field-appearance-fill .mat-form-field-flex {
  background-color: rgb(255 255 255 / 4%) !important;
}

.success-button-lg {
  margin-top: 1.8% !important;
  border-radius: 42px !important;
  background-color: #01c1a8;
  height: 80px;
  width: 75%;
  font-size: 36px;
  color: white;
}

/* .success-button-sm {
    margin-top: 0.5% !important;
    border-radius: 42px !important;
    background-color: #01c1a8;
    height: 35px;
    width: 8%;
    font-size: 17px;
    color: white;
  } */

.danger-button-md {
  float: right;
  margin-top: 0.5% !important;
  border-radius: 42px !important;
  background-color: #c1011a;
  height: 35px;
  width: 8%;
  font-size: 17px;
  color: white;
}

.primary-button-lg {
  margin-top: 1.8% !important;
  border-radius: 42px !important;
  background-color: #4b88a2;
  height: 80px;
  width: 75%;
  font-size: 36px;
  color: black;
}

.success-button-md {
  float: right;
  margin-top: 3.6% !important;
  border-radius: 30px !important;
  background-color: #01c1a8;
  height: 45px;
  width: 111px;
  font-size: 24px;
  color: white;
}

.danger-button-md {
  float: right;
  margin-top: 3.6% !important;
  border-radius: 30px !important;
  background-color: #c1011a;
  height: 45px;
  width: 111px;
  font-size: 24px;
  color: white;
}

.danger-button-sm {
  float: right;
  margin-top: 0.5% !important;
  border-radius: 42px !important;
  background-color: #c1011a;
  height: 35px;
  width: 8%;
  font-size: 17px;
  color: white;
}

.primary-button-md {
  float: right;
  margin-top: 3.6% !important;
  border-radius: 30px !important;
  background-color: #4b88a2;
  height: 45px;
  width: 111px;
  font-size: 24px;
  color: black;
}

.primary-button-sm {
  float: right;
  margin-top: 0.5% !important;
  border-radius: 42px !important;
  background-color: #4b88a2;
  height: 35px;
  width: 8%;
  font-size: 17px;
  color: black;
}

.progress-spinner {
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  background: rgba(0, 0, 0, 0.15);
  z-index: 1000;
  display: flex;
  align-items: center;
  justify-content: center;
}

.progress-spinner-margin {
  margin: 0 10px;
}

.mat-button-focus-overlay {
  background-color: transparent !important;
}

.mat-calendar-body-selected {
  background-color: #c1011a;
  color: #fff;
}

.mat-calendar-body-today:not(.mat-calendar-body-selected) {
  border-color: #c1011a;
}

.red-text {
  color: #c1011a;
}

.capital {
  text-transform: uppercase;
}

.font-27 {
  font-size: 27px;
}

.font-21 {
  font-size: 27px;
}

.add-form-img {
  width: 17%;
}

.modal-head {
  font-size: 27px;
  text-transform: uppercase;
  color: white;
  padding: 27px;
  position: absolute;
  font-weight: bold;
}

.reset-btn {
  background-color: #4b88a2;
  height: 60px;
  width: 100px;
  color: #ffffff;
  font-weight: bold;
  font-family: "Rubik", sans-serif;
  margin-left: 1% !important;
  margin-right: 1% !important;
  font-size: 21px;
  border-radius: 0.8rem !important;
}

.done-btn {
  background-color: #01c1a8;
  height: 60px;
  width: 100px;
  color: #ffffff;
  font-weight: bold;
  font-family: "Rubik", sans-serif;
  margin-left: 1% !important;
  margin-right: 1% !important;
  border-radius: 0.8rem !important;
  font-size: 21px;
}

.save-btn {
  background-color: #357266;
  height: 60px;
  width: 100px;
  color: #ffffff;
  font-weight: bold;
  font-family: "Rubik", sans-serif;
  margin-left: 1% !important;
  margin-right: 1% !important;
  border-radius: 0.8rem !important;
  font-size: 21px;
}

.top-bottom-margin {
  margin: 5% 0 5% 0;
}

.header-height {
  height: 42px !important;
}


#menu-item {
  width: 250px !important;
}


input::placeholder {
  font-size: 14px;
}


.mat-select-panel {
  background: white;
}


.d-flex { display:flex}
.justify-content-between{
  justify-content: space-between;
}

.justify-content-around{
  justify-content: space-around;
}